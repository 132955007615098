import { createGlobalStyle } from 'styled-components';

import { BREAKPOINTS } from '@/theme/breakpoints';

export const GlobalStyles = createGlobalStyle`
  :root {
    --white: #FFFFFF;
    --primary-color: #0D0E13;
    --secondary-color: #FFAD00;
    --tertiary-color: #B2C0D6;
    --background-blue: #F9FAFE;
    --background-blue-dark: #15141a;
    --background-blue-dark-contrast: #212129;
    --text-color: #0D0E13;
    --text-grey: #818195;
    --green: #70DAC0;
  }

  @font-face {
    font-family: 'HK';
    src: url('/fonts/HK-Regular.eot');
    src: local('HK Regular'), local('HK-Regular'),
        url('/fonts/HK-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/HK-Regular.woff2') format('woff2'),
        url('/fonts/HK-Regular.woff') format('woff'),
        url('/fonts/HK-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'HK';
    src: url('/fonts/HK-Medium.eot');
    src: local('HK Medium'), local('HK-Medium'),
        url('/fonts/HK-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/HK-Medium.woff2') format('woff2'),
        url('/fonts/HK-Medium.woff') format('woff'),
        url('/fonts/HK-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  } 

  @font-face {
    font-family: 'HK';
    src: url('/fonts/HK-SemiBold.eot');
    src: local('HK SemiBold'), local('HK-SemiBold'),
        url('/fonts/HK-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/HK-SemiBold.woff2') format('woff2'),
        url('/fonts/HK-SemiBold.woff') format('woff'),
        url('/fonts/HK-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  html, body {
    height: 100%;
    scroll-behavior: smooth;
    background-color: var(--background);
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: 'HK', sans-serif;
    color: var(--text-color);
    accent-color: var(--primary-color);
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  input, button, textarea, select {
    font: inherit;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    letter-spacing: -0.02rem;
  }

  h1 {
    font-size: 48px;
    font-weight: 500;
    line-height: 54px;
    margin-bottom: 28px;
  }

  h2 {
    font-size: 38px;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 28px;
  }

  h3 {
    font-size: 34px;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 28px;
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 28px;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  
  #root, #__next {
    isolation: isolate;
    height: 100%;
  }

  html.has-scroll-smooth {
    overflow: hidden;
  }

  html.has-scroll-dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .has-scroll-smooth body {
    overflow: hidden; }

  .has-scroll-smooth [data-scroll-container] {
    min-height: 100vh; }

  [data-scroll-direction="horizontal"] [data-scroll-container] {
    height: 100vh;
    display: inline-block;
    white-space: nowrap;
  }

  [data-scroll-direction="horizontal"] [data-scroll-section] {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    height: 100%;
  }

  .c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;

    &:hover {
      transform: scaleX(1.45);
    }
  }

  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1;
  }

  [data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1);
  }

  [data-scroll-direction="horizontal"] .c-scrollbar:hover {
    transform: scaleY(1.3);
  }

  .c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab;
  }
    
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0;
  }

  .fadeInUp {
    transition: all 0.6s ease-in;
    opacity: 0;

    &.loaded {
      opacity: 1;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      background-color: white;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    h1 {
      font-size: 32px;
      font-weight: 500;
      line-height: 42px;
      margin-bottom: 28px;
    }

    h2 {
      font-size: 26px;
      font-weight: 500;
      line-height: 34px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
`;
