/* eslint-disable import/no-unresolved */
import splitbee from '@splitbee/web';
import { Analytics } from '@vercel/analytics/react';
import Router from 'next/router';
import { useEffect } from 'react';

import { GlobalStyles } from '@/components';
import { trackPageView } from '@/lib/gtm/gtm';
import type { AppPropsWithLayout } from '@/types';

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page) => page);

  useEffect(() => {
    const handleRouteChange = (url: string) => trackPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect((): void => {
    splitbee.init({
      apiUrl: '/sb-api',
      scriptUrl: '/sb.js',
    });
  }, []);

  return getLayout(
    <div id="main-page-wrap">
      <Component {...pageProps} />
      <GlobalStyles />
      <Analytics />
    </div>
  );
};

export default App;
