import NextHead from 'next/head';

interface THead {
  title?: string;
  description?: string;
  keywords?: string;
  author?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImage?: string;
}

export const Head = ({
  title,
  description,
  keywords,
  author,
  ogTitle,
  ogDescription,
  ogUrl,
  ogImage,
}: THead) => (
  <NextHead>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="author" content={author} />
    <meta property="og:title" content={ogTitle} />
    <meta property="og:description" content={ogDescription} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={ogUrl} />
    <meta property="og:image" content={ogImage} />
    <meta name="robots" content="follow, index" />
  </NextHead>
);

Head.defaultProps = {
  author: 'Hannah King',
  description:
    'Hannah King is an award-winning, freelance creative developer with over 20 years of web development experience. Developer of the Year Winner',
  keywords: 'JAMstack, web developer, creative developer, Next.js, Wordpress',
  ogDescription:
    'Hannah King is an award-winning, freelance creative developer with over 20 years of web development experience. Developer of the Year Winner ',
  ogImage: 'https://hannah-king-folio.vercel.app/images/og.png',
  ogTitle: ' Hannah King ⚡️ Award-winning Creative Developer',
  ogUrl: 'https://hannahking.com',
  title: 'Hannah King ⚡️ Award-winning Creative Developer',
};
