/* eslint-disable no-unused-expressions */
import type { GTMEvent, PageViewEvent } from './types';

export const sendGTMEvent = (event: GTMEvent) => {
  // @ts-ignore
  window && window.dataLayer && window.dataLayer.push(event);
};

export const trackPageView = (url: string) => {
  const event: PageViewEvent = {
    event: 'pageViewTriggered',
    page: url,
  };
  sendGTMEvent(event);
  return event;
};
