import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';

import { Button, Container } from '../Shared';

const StyledFooter = styled.div`
  color: white;
  padding: 50px 0;
  background-color: var(--background-blue-dark-contrast);
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledContact = styled.div`
  width: 60%;

  h1 {
    font-size: 5rem;
    line-height: 120%;
  }

  a {
    border-color: white;
    color: white;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;

    h1 {
      font-size: 3rem;
    }
  }
`;

const StyledRight = styled.div`
  flex: 1;
  text-align: right;

  a {
    box-shadow: inset 0 0 0 2px white;
    color: white;
    text-transform: none;
    font-size: 1rem;
    margin-bottom: 2rem;

    &:hover {
      color: white;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;

    a {
      margin: 0 0 20px;
    }
  }
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <Container size="narrow">
        <StyledRow>
          <StyledContact>
            <h1>Let's Work Together</h1>
          </StyledContact>
          <StyledRight>
            <Button
              type="secondary"
              href="mailto:hello@hannahking.com"
              nextLink={false}
            >
              hello@hannahking.com
            </Button>
            <Button type="secondary" href="tel:447814337544" nextLink={false}>
              +44 0781 433 7544
            </Button>
          </StyledRight>
        </StyledRow>
      </Container>
    </StyledFooter>
  );
};
