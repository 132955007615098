import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme/breakpoints';

const CONTAINER_WIDTH = 1350;
const CONTAINER_WIDTH_NARROW = 1100;

export const Container = styled.div<{
  size?: 'narrow';
  padding?: string;
  flex?: boolean;
  align?: string;
  direction?: string;
  height?: string;
}>`
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  max-width: ${CONTAINER_WIDTH}px;
  ${(props) => props.flex === true && `display:flex;`}
  ${(props) => props.align === 'center' && `align-items: center;`}
  ${(props) => props.align === 'center-desktop' && `align-items: center;`}
  ${(props) => props.align === 'top' && `align-items: flex-start;`}
  ${(props) => props.direction && `flex-direction: ${props.direction};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) =>
    props.size === 'narrow' && `max-width: ${CONTAINER_WIDTH_NARROW}px;`}
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    ${(props) => props.align === 'center-desktop' && `align-items: flex-start;`}
  }
`;
