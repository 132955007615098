import Link from 'next/link';
import type { ReactChild } from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme/breakpoints';

type TButton = {
  type: string;
  href: string;
  children: ReactChild;
  newWindow?: boolean;
  nextLink?: boolean;
};

const StyledButton = styled.a<{
  type: string;
}>`
  display: inline-block;
  margin: 0 auto;
  padding: 16px 40px;
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  will-change: background-color, color, box-shadow;
  transition: background-color 0.3s ease-in, color 0.3s ease-in,
    box-shadow 0.3s ease-in;
  ${(props) =>
    props.type === 'primary' &&
    `background-color: var(--background-blue-dark);
        color: white;
        &:hover {
            background-color: var(--background-blue-dark-contrast);
            color: white;
        }
    `}
  ${(props) =>
    props.type === 'secondary' &&
    `background-color: transparent;
        color: var(--text-color);
        box-shadow: inset 0 0 0 2px var(--primary-color);
        &:hover {
            box-shadow: inset 0 0 0 2px var(--green);
            color: var(--text-color);
        }
    `}
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 14px 26px;
  }
`;

export const Button = ({
  type,
  href,
  children,
  newWindow = false,
  nextLink = true,
}: TButton) => {
  return (
    <>
      {nextLink ? (
        <Link href={href} passHref>
          <StyledButton
            target={`${newWindow ? '_blank' : '_self'}`}
            type={type}
          >
            {children}
          </StyledButton>
        </Link>
      ) : (
        <StyledButton
          href={href}
          target={`${newWindow ? '_blank' : '_self'}`}
          type={type}
        >
          {children}
        </StyledButton>
      )}
    </>
  );
};
